@font-face {
  font-family: 'Gotham Black';
  src: local('Gotham-Black'), url(./fonts/Gotham-Black.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham Book';
  src: local('Gotham-Book'), url(./fonts/Gotham-Book.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham Medium';
  src: local('Gotham-Medium'), url(./fonts/Gotham-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'Portico';
  src: local('Portico'), url(./fonts/Portico.otf) format('opentype');
}

body {
  margin: 0;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
